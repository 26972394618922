<template>
  <div class="vx-row">
    <div class="vx-col md:w-1/3 w-full">
      <vx-card title="รูปภาพองค์กร">
        <div class="vx-row">
          <div class="vx-col sm:w-1/2 w-full text-center" style="margin: auto 0;">
            <div v-if="urlLogo === ''">
              <vs-icon icon="broken_image" style="font-size: 40px"></vs-icon>
              <div>ไม่มีรูปภาพ</div>
            </div>
            <img v-else :src="urlLogo" class="rounded w-3/4" style="display: inline-block"/>
          </div>
          <div class="vx-col sm:w-1/2 w-full text-center">
<!--            <input type="file" class="input-file" @change="update_logo" accept="image/*"/>-->
<!--            <vs-button color="warning" type="filled" class="btn-upload mb-2">เปลี่ยนรูปภาพ</vs-button>-->
<!--            <vs-button color="primary" type="filled" class="btn-upload" @click="update_logo_image()">บันทึกรูปภาพ-->
<!--            </vs-button>-->
          </div>
        </div>
      </vx-card>
    </div>
    <div class="vx-col md:w-1/3 w-full">
      <vx-card title="รูปลายเซ็นต์รายงาน">
        <div class="vx-row">
          <div class="vx-col sm:w-1/2 w-full text-center" style="margin: auto 0;">
            <div v-if="urlReport === ''">
              <vs-icon icon="broken_image" style="font-size: 40px"></vs-icon>
              <div>ไม่มีรูปภาพ</div>
            </div>
            <img v-else :src="urlReport" class="rounded w-3/4" style="display: inline-block"/>
          </div>
          <div class="vx-col sm:w-1/2 w-full text-center">
<!--            <input type="file" class="input-file" accept="image/*" @change="update_report">-->
<!--            <vs-button color="warning" type="filled" class="btn-upload mb-2">เปลี่ยนรูปภาพ</vs-button>-->
<!--            <vs-button color="primary" type="filled" class="btn-upload" @click="update_report_image()">บันทึกรูปภาพ</vs-button>-->
          </div>
        </div>
      </vx-card>
    </div>
    <div class="vx-col md:w-1/3 w-full mb-6">
      <vx-card title="รูปลายเซ็นต์ใบเตือน">
        <div class="vx-row">
          <div class="vx-col sm:w-1/2 w-full text-center" style="margin: auto 0;">
            <div v-if="urlWarning === ''">
              <vs-icon icon="broken_image" style="font-size: 40px"></vs-icon>
              <div>ไม่มีรูปภาพ</div>
            </div>
            <img v-else :src=urlWarning class="rounded w-3/4" style="display: inline-block"/>
          </div>
          <div class="vx-col sm:w-1/2 w-full text-center">
<!--            <input type="file" class="input-file" accept="image/*" @change="update_warning">-->
<!--            <vs-button color="warning" type="filled" class="btn-upload mb-2">เปลี่ยนรูปภาพ</vs-button>-->
<!--            <vs-button color="primary" type="filled" class="btn-upload" @click="update_warning_image()">บันทึกรูปภาพ</vs-button>-->
          </div>
        </div>
      </vx-card>
    </div>
    <div class="vx-col w-full mt-2">
      <vx-card title="ข้อมูลองค์กร">
        <div class="vx-row mb-4 mt-4">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col md:w-1/3 w-full">
                ชื่อองค์กร:
              </div>
              <div class="vx-col md:w-2/3 w-full">
                <vs-input class="w-full" name="name" v-model="orgData.Org01"/>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col md:w-1/3 w-full">
                ชื่อหน่วยงาน:
              </div>
              <div class="vx-col md:w-2/3 w-full">
                <vs-input class="w-full" v-model="orgData.Org02"/>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-4">
          <div class="vx-col md:w-1/6 w-full">
            ที่อยู่:
          </div>
          <div class="vx-col md:w-5/6 w-ful">
            <vs-input class="w-full" v-model="orgData.Org03"/>
          </div>
        </div>
        <div class="vx-row mb-4">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col md:w-1/3 w-full">
                ตัวย่อ:
              </div>
              <div class="vx-col md:w-2/3 w-full">
                <vs-input class="w-full" v-model="orgData.Org04"/>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col md:w-1/3 w-full">
                เบอร์โทร:
              </div>
              <div class="vx-col md:w-2/3 w-full">
                <vs-input class="w-full" v-model="orgData.Org05"/>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-4">
          <div class="vx-col md:w-1/6 w-full">
            หัวใบแจ้งหนี้่:
          </div>
          <div class="vx-col md:w-5/6 w-ful">
            <vs-input class="w-full" v-model="orgData.TempHeader" readonly/>
          </div>
        </div>
        <div class="vx-row mb-4">
          <div class="vx-col md:w-1/6 w-full">
            ท้ายใบแจ้งหนี้่:
          </div>
          <div class="vx-col md:w-5/6 w-ful">
            <vs-input class="w-full" v-model="orgData.TempFooter" readonly/>
          </div>
        </div>
        <div class="vx-row mb-4">
          <div class="vx-col md:w-1/6 w-full">
            หัวใบแทนใบเสร็จ:
          </div>
          <div class="vx-col md:w-5/6 w-ful">
            <vs-input class="w-full" v-model="orgData.ReceiveHeader" readonly/>
          </div>
        </div>
        <div class="vx-row mb-4">
          <div class="vx-col md:w-1/6 w-full">
            ท้ายใบแทนใบเสร็จ:
          </div>
          <div class="vx-col md:w-5/6 w-ful">
            <vs-input class="w-full" v-model="orgData.ReceiveFooter" readonly/>
          </div>
        </div>
        <div class="vx-row mb-4">
          <div class="vx-col md:w-1/6 w-full">
            ใบเสร็จหมดอายุหลังจาก/วัน:
          </div>
          <div class="vx-col md:w-5/6 w-ful">
            <vs-input class="w-full" v-model="orgData.billExpired" readonly/>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full text-right mt-3">
            <vs-button color="danger" type="filled">ยกเลิก</vs-button>
            <vs-button color="primary" type="filled" class="ml-4" @click="updateData()">บันทึก</vs-button>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>
<script>
  import moduleConfig from "@/store/config/moduleConfig.js";

  export default {
    components: {},
    data() {
      return {
        user: '',
        defaultUrl: 'http://188.166.177.25/assets/images/logo/noImage.png',
        baseUrl: 'http://188.166.177.25/',
        urlLogo: '',
        logoImg: '',
        reportImg: '',
        warningImg: '',
        urlReport: '',
        urlWarning: '',
        orgData: {
          _id: '',
          Org01: '',
          Org02: '',
          Org03: '',
          Org04: '',
          Org05: '',
          TempHeader: '',
          TempFooter: '',
          ReceiveHeader: '',
          ReceiveFooter: '',
          billExpired: 0,
        }
      }
    },
    computed: {
      // org() {
      //   return this.$store.state.config.item
      // },
    },
    methods: {
      async init() {
        let data = await this.$store.state.config.item;
        this.orgData = {
          _id: data._id,
          Org01: data.Org01,
          Org02: data.Org02,
          Org03: data.Org03,
          Org04: data.Org04,
          Org05: data.Org05,
          TempHeader: data.TempHeader,
          TempFooter: data.TempFooter,
          ReceiveHeader: data.ReceiveHeader,
          ReceiveFooter: data.ReceiveFooter,
          billExpired: data.billExpired,
          logo_image_url: data.logo_image_url,
          warning_image_url: data.warning_image_url,
          report_image_url: data.report_image_url,
        };
        if (this.orgData.logo_image_url !== '' && this.orgData.logo_image_url !== null && this.orgData.logo_image_url !== undefined) {
          this.urlLogo = this.baseUrl + this.orgData.logo_image_url;
        }
        if (this.orgData.warning_image_url !== '' && this.orgData.warning_image_url !== null && this.orgData.warning_image_url !== undefined) {
          this.urlWarning = this.baseUrl + this.orgData.warning_image_url;
        }
        if (this.orgData.report_image_url !== '' && this.orgData.report_image_url !== null && this.orgData.report_image_url !== undefined) {
          this.urlReport = this.baseUrl + this.orgData.report_image_url;
        }
        this.$vs.loading.close();
      },
      async updateData() {
        await this.$store.dispatch("config/updateItem", {
          id: this.orgData._id,
          _id: this.orgData._id,
          Org01: this.orgData.Org01,
          Org02: this.orgData.Org02,
          Org03: this.orgData.Org03,
          Org04: this.orgData.Org04,
          Org05: this.orgData.Org05
        }).then((res) => {
          if (res.status === 200) {
            let data = res.data;
            this.orgData = {
              id: data._id,
              _id: data._id,
              Org01: data.Org01,
              Org02: data.Org02,
              Org03: data.Org03,
              Org04: data.Org04,
              Org05: data.Org05,
              TempHeader: data.TempHeader,
              TempFooter: data.TempFooter,
              ReceiveHeader: data.ReceiveHeader,
              ReceiveFooter: data.ReceiveFooter,
              billExpired: data.billExpired,
            };
            this.successDialog();
          } else {
            this.failDialog();
          }
        }).catch(error => {
          this.failDialog();
        });
      },
      update_logo(input) {
        if (input.target.files && input.target.files[0]) {
          this.logoImg = input.target.files[0];
          var reader = new FileReader();
          reader.onload = e => {
            this.urlLogo = e.target.result;
          };
          reader.readAsDataURL(input.target.files[0]);
        }
      },
      update_report(input) {
        if (input.target.files && input.target.files[0]) {
          this.reportImg = input.target.files[0];
          var reader = new FileReader();
          reader.onload = e => {
            this.urlReport = e.target.result;
          };
          reader.readAsDataURL(input.target.files[0]);
        }
      },
      update_warning(input) {
        if (input.target.files && input.target.files[0]) {
          this.warningImg = input.target.files[0];
          var reader = new FileReader();
          reader.onload = e => {
            this.urlWarning = e.target.result;
          };
          reader.readAsDataURL(input.target.files[0]);
        }
      },
      async update_logo_image() {
        const formData = new FormData();
        formData.append("id", this.orgData._id);
        formData.append("name", 'logo');
        formData.append("file", this.logoImg);
        await this.$store.dispatch("config/updateImage", formData)
          .then(response => {
            if (response.success) {
            }
          })
          .catch(err => {
            console.error(err);
          });
      },
      async update_report_image() {
        const formData = new FormData();
        formData.append("id", this.orgData._id);
        formData.append("name", 'report');
        formData.append("file", this.reportImg);
        await this.$store.dispatch("config/updateImage", formData)
          .then(response => {
            if (response.success) {
            }
          })
          .catch(err => {
            console.error(err);
          });
      },
      async update_warning_image() {
        const formData = new FormData();
        formData.append("id", this.orgData._id);
        formData.append("name", 'warning');
        formData.append("file", this.warningImg);
        await this.$store.dispatch("config/updateImage", formData)
          .then(response => {
            if (response.success) {
            }
          })
          .catch(err => {
            console.error(err);
          });
      },
      failDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'danger',
          title: `ผิดพลาด`,
          text: 'แก้ไขข้อมูลองค์กรไม่สำเร็จ',
          acceptText: 'ปิด'
        })
      },
      successDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'success',
          title: `สำเร็จ`,
          text: 'แก้ไขข้อมูลองค์กรสำเร็จ',
          acceptText: 'ปิด'
        })
      }
    },
    async created() {
      this.$vs.loading();
      if (!moduleConfig.isRegistered) {
        this.$store.registerModule('config', moduleConfig);
        moduleConfig.isRegistered = true
      }
      await this.$store.dispatch("config/fetchDataItem");
      this.init();
    }
  }
</script>
<style lang="scss">
  .input-file {
    position: absolute;
    z-index: 5;
    width: 111px;
    height: 39px;
    opacity: 0;
  }

  .btn-upload:not(.vs-radius):not(.includeIconOnly):not(.small):not(.large) {
    width: 100%;
    padding: .75rem;
  }
</style>
